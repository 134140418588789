'use client';

import { LoadingOrError } from 'components/LoadingOrError';
import { usePageError } from '@mentimeter/errors/usePageError';

export default function Error({ error }: { error: Error }) {
  usePageError({
    error,
    message: 'Something went wrong in view page',
    feature: 'slides',
  });
  return <LoadingOrError error="Something went wrong" />;
}
